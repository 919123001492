import React from 'react';
import fetch from 'unfetch';

import { SEO, Input, Promotion, Icon, Container } from '@components';

import * as s from '../styles/contact.module.scss';

declare global {
    interface Window {
        GRECAPTCHA_KEY: string;
    }
}

function ContactPage() {
    const [status, setStatus] = React.useState('');
    const [state, setState] = React.useState({
        'form-name': 'contact',
        'bot-field': '',
        name: '',
        email: '',
        phone: '',
        address: '',
        message: '',
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        setState({ ...state, [event.target.name]: event.target.value });

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        if (status === 'SUCCESS') {
            return;
        }

        grecaptcha.ready(() => {
            grecaptcha.execute(window.GRECAPTCHA_KEY as string, { action: 'contact' }).then((token) => {
                const body = Object.entries({ ...state, 'g-recaptcha-response': token })
                    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                    .join('&');

                fetch('/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body,
                }).then(({ status: statusCode }) => {
                    if (statusCode === 200) {
                        setStatus('SUCCESS');
                    }
                    setState({
                        ...state,
                        name: '',
                        email: '',
                        phone: '',
                        address: '',
                        message: '',
                    });
                });
            });
        });
    };

    return (
        <Container>
            <SEO title="Contact Us" />
            <h1 className={s.title}>We&apos;re excited to learn about your project</h1>
            <p className={s.text}>
                Request a free full estimate or ask a simple question. <br />
                We&apos;re happy to help you with all your home remodeling and construction needs.
            </p>
            <div className={s.promotion}>
                <Promotion
                    link="https://apply.svcfin.com/home/dealerAuthentication?id=815126916&key=1742219857"
                    linkName="Apply Now"
                    icon={<Icon name="finance" />}
                >
                    Let your dream become reality. Enjoy deferred or no interest on your next project with a qualified
                    loan.
                </Promotion>
            </div>
            <form
                className={s.form}
                name="contact"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                data-netlify-recaptcha="true"
                onSubmit={handleFormSubmit}
            >
                <input type="hidden" name="form-name" value="contact" />
                <input id="bot-field" name="bot-field" value={state['bot-field']} onChange={handleInputChange} />
                <div className={s.formControls}>
                    <Input
                        type="text"
                        title="First and Last Name"
                        name="name"
                        placeholder="Name"
                        value={state.name}
                        onChange={handleInputChange}
                    />
                    <Input
                        type="email"
                        title="Email Address"
                        name="email"
                        placeholder="Email"
                        value={state.email}
                        onChange={handleInputChange}
                    />
                    <Input
                        type="tel"
                        title="Primary Phone Numbers"
                        name="phone"
                        placeholder="Phone Number"
                        value={state.phone}
                        onChange={handleInputChange}
                    />
                    <Input
                        type="text"
                        title="Project Zip Code Or Address"
                        name="address"
                        placeholder="Zip Code or Address"
                        value={state.address}
                        onChange={handleInputChange}
                    />
                    <Input
                        type="textarea"
                        title="Message"
                        name="message"
                        placeholder="What can we help you with?"
                        value={state.message}
                        onChange={handleInputChange}
                    />
                </div>
                <button className={s.button} type="submit">
                    Send message
                </button>
                {status === 'SUCCESS' && (
                    <div className={s.formMessage}>Thank you. We will get back to you as soon as possible.</div>
                )}
            </form>
        </Container>
    );
}

export default ContactPage;
